/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'
import UserService from "@/services/user";

// import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  // modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  state: {
    status: '',
    token: localStorage.getItem('mundoficina-token') || '',
    user: {}
  },
  mutations: {
    ['AUTH_REQUEST']: (state) => {
      state.status = 'loading'
    },
    ['AUTH_SUCCESS']: (state, token) => {
      state.status = 'success'
      state.token = token
    },
    ['AUTH_ERROR']: (state) => {
      state.status = 'error'
    },
    ['AUTH_LOGOUT']: (state) => {
      state.status = ''
      state.token = ''
    },
    ['USER_DATA']: (state, data) => {
      state.user = data
    },
  },
  actions: {
    ['AUTH_REQUEST']: ({commit, dispatch}, data) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST')
        UserService.login(data.email, data.password)
          .then(res => {
            const token = res.data.token
            localStorage.setItem('mundoficina-token', token) // store the token in localstorage

            commit('AUTH_SUCCESS', token)
            // you have your token, now log in your user :)
            resolve(res)
          })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('mundoficina-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
      })
    },
    ['AUTH_LOGOUT']: ({commit}) => {
      return new Promise((resolve) => {
        localStorage.removeItem('mundoficina-token') // clear your user's token from localstorage
        commit('USER_DATA', {});
        commit('AUTH_LOGOUT')
        resolve()
      })
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    userData: state => state.user,
  },
  strict: process.env.NODE_ENV !== 'production',
})

export default store

