import api from '@/services/api'

export default {
  login(email, password) {
    return api.post('/admin/auth', { email, password })
  },

  getData() {
    return api.get('/admin')
  },

  getStats() {
    return api.get('/admin/stats')
  },

  getServices() {
    return api.get(`/admin/services`)
  },

  getHirers(page, params) {
    let query = '';
    
    if (!params.export) {
      query = `?page=${page}`;
    }

    if (params.export) {
      query = '?export=true';
    }

    if (params.term) {
      query += `&term=${params.term}`;
    }

    if (params.city) {
      query += `&city=${params.city}`;
    }

    if (params.state) {
      query += `&state=${params.state}`;
    }

    if (params.all) {
      query += '&all=true';
    }

    if (params.export) {
      return api({
        url: `/admin/hirers${query}`,
        method: 'GET',
        responseType: 'blob',
      })
    }

    return api.get(`/admin/hirers${query}`);
  },

  getProviders(page, params) {
    let query = '';
    
    if (!params.export) {
      query = `?page=${page}`;
    }

    if (params.export) {
      query = '?export=true';
    }

    if (params.term) {
      query += `&term=${params.term}`;
    }

    if (params.service) {
      query += `&service=${params.service}`;
    }

    if (params.city) {
      query += `&city=${params.city}`;
    }

    if (params.state) {
      query += `&state=${params.state}`;
    }

    if (params.bankDataStatus) {
      query += `&bankDataStatus=${params.bankDataStatus}`;
    }

    if (params.providerStatus) {
      query += `&providerStatus=${params.providerStatus}`;
    }

    if (params.all) {
      query += '&all=true';
    }

    if (params.export) {
      return api({
        url: `/admin/providers${query}`,
        method: 'GET',
        responseType: 'blob',
      })
    }

    return api.get(`/admin/providers${query}`);
  },

  getUserById(id) {
    return api.get(`/users/${id}`)
  },

  updateProvider(id, data) {
    return api.put(`/providers/${id}`, data)
  },

  updateHirer(id, data) {
    return api.put(`/hirers/${id}`, data)
  },

  changePassword(id, newPassword) {
    return api.post(`/users/change-password/${id}`, { newPassword })
  },

  createProvider(data) {
    return api.post('/providers', data)
  },

  blockProvider(id, data) {
    return api.put(`/providers/block/${id}`, data)
  },

  unblockProvider(id, data) {
    return api.put(`/providers/unblock/${id}`, data)
  },
}